import React from 'react';

function Map() {
  return (
    <div id="map" className="text-center"><br/>
        <div className="section-title">
          <h2>Map</h2>
          <p>
          7star Power Control System, Thalaivasal, Salem Dt. Phone No : +918675276668 
          </p>
        </div>
      <div
        id="map-container-google-1"
        className="z-depth-1-half"
        style={{ height: '500px', width: '100%', paddingLeft:'30px',  paddingRight:'30px' }}
      >
        <iframe
          title="Google Map"
          
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d31268.405119390347!2d78.74296384067688!3d11.584037128061308!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bab778027023837%3A0x43fad27b3b86db76!2s7star%20Power%20Control%20System!5e0!3m2!1sen!2sin!4v1705906045670!5m2!1sen!2sin"
          className="p-4 shadow-lg mb-5 bg-body-tertiary rounded"
          allowFullScreen
          style={{ height: '500px', width: '100%', padding:'20px' }}
        ></iframe>
      </div>
    </div>
  );
}

export default Map;
