import React from "react";
import brand from "../image/01.jpeg"
import brand1 from "../image/brand1.jpeg"
import brand2 from "../image/brand2.jpeg"
import brand3 from "../image/brand3.jpeg"

export const Team = (props) => {
  return (<><br/>
    <div id="brand" className="text-center">
      <div className="container">
        <div className=" section-title">
          <h2>Brand Available</h2>
          <p>
          These brands are recognized globally for their contributions to the field of power control systems. If you have specific requirements or if you are looking for a particular type of power control system, it's advisable to explore the offerings and solutions provided by each brand to determine the best fit for your needs.
          </p>
          <img src={brand} alt="..." className="brand-img"/>
          <div><img src={brand1} alt="..." className="new-brand"/>
          <img src={brand2} alt="..." className="new-brand"/>
          <img src={brand3} alt="..." className="new-brand"/></div>
        </div>
        
      </div>
    </div>
    </>);
};
